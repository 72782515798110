<template>
  <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="faktura"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      :html-to-pdf-options="options"
  >
    <section slot="pdf-content">


      <div class="invoice_outline inv_body">

        <table  class="clean to_half">
          <tr>
            <td >
              <p>
                <strong class="big_text">Faktura VAT {{data.nr}}</strong>
              </p>
              <p> </p>
              <p>
                <strong>Miejsce wystawienia:</strong> {{ data.config.city }}<br/>
                <strong>Data sprzedaży:</strong> {{data.date}}<br/>
                <strong>Rodzaj płatności:</strong>Przelew<br/>
                <strong>Termin płatności:</strong>14 dni
              </p>
            </td>
            <td class="logo_inside" style="text-align: right;">
            </td>
          </tr>
        </table>

        <hr />

        <table class="clean split_half  main_inv_table">
          <thead>
          <tr>
            <th style="width:50%">
              <span class="seller">Sprzedawca</span>
            </th>
            <th style="width:50%">
              <span class="buyer">Nabywca</span>
            </th>
          </tr>

          </thead>
          <tbody>

          <tr>

            <td>
              <p>{{ data.config.name }}</p>
              <p>{{ data.config.address }}</p>
              <p>{{ data.config.postcode }} {{ data.config.city }}</p>
              <p>NIP: {{ data.config.nip }}</p>
              <p>REGON: {{ data.config.regon }}</p>
              <p>Tel: {{ data.config.phone }}</p>

            </td>

            <td>
              <p>{{ data.fund.name }}</p>
              <p>{{ data.fund.address }}</p>
              <p>{{ data.fund.postcode}} {{ data.fund.city }}</p>
              <p>NIP: {{data.fund.nip}}</p>


            </td>
          </tr>

          </tbody>
        </table>


        <table class="main_inv_table">


          <thead>
          <tr>
            <th class="width0">
              Lp.
            </th>

            <th class="width4 text_left">
              Produkt/usługa
            </th>

            <th class="width1">
              Ilość
            </th>

            <th class="width3 text_right ">
              Cena netto
            </th>

            <th class="width3 text_right ">
              Stawka VAT
            </th>

            <th class="width3 text_right ">
              Wartość netto
            </th>

            <th class="width3 text_right ">
              Kwota VAT
            </th>

            <th class="width3 text_right ">
              Wartość brutto
            </th>

          </tr>
          </thead>
          <tbody>

          <tr>
            <td>
              1
            </td>
            <td class="text_left">
              {{ data.name }}
            </td>

            <td class="text_center">
              {{ data.count }}
            </td>
            <td class="text_right number">
              {{ data.price }}
            </td>

            <td class="text_right number">
              0%
            </td>

            <td class="text_right number">
              {{ invSum }}
            </td>

            <td class="text_right number">
              0
            </td>

            <td class="text_right number">
              {{ invSum }}
            </td>

          </tr>


          <tr class="clean no_border">

            <td class="text_right " colspan="5"><b>RAZEM</b></td>


            <td class="text_right number">
              {{ invSum }}
            </td>

            <td class="text_right number">
              0
            </td>

            <td class="text_right number">
              {{ invSum }}
            </td>
          </tr>


          </tbody>
        </table>

        <div class="clear"></div>


        <table class="main_inv_table to_right" style="width:50%; padding-left:200px;">
          <thead>
          <tr>
            <th class="width3 text_right ">&nbsp;</th>
            <th class="width3 text_right ">VAT</th>
            <th class="width3 text_right ">Wartość netto</th>
            <th class="width3 text_right ">Wartość VAT</th>
            <th class="width3 text_right ">Wartość brutto</th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td class="text_right number">&nbsp;</td>
            <td class="text_right number">0%</td>
            <td class="text_right number">{{ invSum }}</td>
            <td class="text_right number">0</td>
            <td class="text_right number">{{ invSum }}</td>
          </tr>
          <tr>
            <td class="text_right number"><b>RAZEM</b></td>
            <td class="text_right number">0%</td>
            <td class="text_right number">{{ invSum }}</td>
            <td class="text_right number">0</td>
            <td class="text_right number">{{ invSum }}</td>
          </tr>
          </tbody>

        </table>


        <br/>
        <div class="clear"></div>

        <table class="clean to_pay to_right" style="width:60%;">
          <tbody>

          <tr class="inv_to_pay">

            <th>Do zapłaty:</th>
            <td>{{ invSum }} PLN</td>
          </tr>

          <tr class="inv_to_pay">

            <th>Słownie:</th>
            <td>{{invSumWords}}</td>
          </tr>



          </tbody>
        </table>

      </div>
    </section>
  </vue-html2pdf>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: "InvoicePdf2",
  props: ["data"],
  data: () => ({
    invoices: [],
    pdfInvoice: null,
    options: {
      margin: 5,
    }
  }),
  mounted(){
    //
  },
  components: {
    VueHtml2pdf
  },
  methods: {
    generatePdf(){
      this.$refs.html2Pdf.generatePdf();
    }
  },
  computed: {
    invSum() {
      let r = (parseInt(this.data.count) * parseFloat(this.data.price.replace(',', '.'))).toFixed(2);
      if (isNaN(r))
        r = 'Błędne dane';
      return r;
    },
    invSumWords(){
      let sumParts = this.data.sum.split('.');
      let slownie = new window.slownie.Slownie().get(sumParts[0]) + ' zł ';
      if(sumParts.length>1)
        slownie += sumParts[1] + '/100';
      else
        slownie += '00/100';

      return slownie;
    }
  }
}
</script>

<style scoped>

  .inv_body {
    font-family: DejaVu Sans;
  }



  .main_inv_table p{margin-bottom:0.5em;line-height:1.4em;}
  .split_three strong{display:block;margin-bottom:5px;}
  .split_three br{display:none}
  #custom_stamp {background-position: 96% 87% !important}


  td.logo_inside img {
    max-height: 187px;
    max-width: 250px;

  }


  table.main_inv_table{margin:auto;clear:both;margin-top:10px;border-collapse:collapse ;empty-cells:hide;margin:5px auto 5px auto;width:99%;}
  table.main_inv_table tr{vertical-align:top;}

  .invtable, table th, table td{border:0;background-color:transparent;text-align:left;vertical-align:top;}

  table.main_inv_table th,table.main_inv_table td,table.main_inv_table caption{text-align:right;border-collapse:collapse;border:1px solid #d8d7d7 ;}
  table.main_inv_table th{font-weight:bold;background-color:#f1f1f1 ;border:1px solid #bbbbbb ;font-size:12px;}
  table.main_inv_table th p,table td a{text-align:left;}

  table th {
    font-weight: bold;
  }

  table.to_right{float:right;width:99%;}
  table.to_right th{text-align:right;}
  table.to_right td{width:15%;text-align:right;}
  .split_half td{width:50%;}
  .split_three td{width:33%;}

  table.to_pay{margin:40px auto 20px auto;background:transparent;}
  table.to_pay th,
  table.to_pay td{border-top:1px solid #ddd;border-bottom:1px solid #ddd;}
  hr{margin:10px auto;}
  .to_pay th{width:120px;}
  .to_pay td,
  .to_pay th{padding:10px 5px;}
  table th,table td{margin:0;padding:5px;}



  #exchange_currency {margin-top:20px;}
  #exchange_currency td {border-top:1px solid #ddd; padding-top:10px !important;}
  #exchange_currency  + table.clean.to_pay {margin-top:0;}


  table td.empty{border:0;}
  html body table tr:hover{background-color:transparent;}
  table.main_inv_table 	th.text_left,
  table.main_inv_table td.text_left,
  th.text_left,
  td.text_left{text-align:left;padding-left:10px;}
  .number,.nowrap,
  td b, td.row1, td.row2, td.row3{white-space:nowrap}

  th.width3 + th.width1.nowrap {white-space:normal}



  .payment_button {
    display:block;
    float:right;
    text-align:right;
    height:43px;
    width:190px;
    z-index:999;
  }

  .payment_button img{
    display:block;
    float:right;
    text-align:right;
    width:190px;
    height:43px;
    z-index:999;
  }


  .invoice_outline[dir="rtl"],
  .invoice_outline[dir="rtl"] table,
  .invoice_outline[dir="rtl"] table th,
  .invoice_outline[dir="rtl"] table td
  {
    text-align:right;
  }

  .invoice_outline[dir="rtl"] .inv_paid > td,
  .invoice_outline[dir="rtl"] .inv_to_pay > td{
    text-align:right;
  }


  .invoice_outline[dir="rtl"] table.clean.to_right td,
  .invoice_outline[dir="rtl"] table.clean.to_right th {text-align:left;}

  .invoice_outline{
    padding-left:30px;
    padding-right: 40px;
    padding-top:35px;
  }

  .big_text{
    font-size: x-large;
  }


</style>
<template>
  <v-card class="mx-auto" max-width="100%">
    <v-system-bar color="indigo darken-2" dark></v-system-bar>
    <v-toolbar color="indigo" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Import faktur z raportu szczepień</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="loadFiles">
        <v-icon>mdi-import</v-icon> Importuj raporty
      </v-btn>
    </v-toolbar>

    <v-container fluid>
      <v-row dense>
        <v-col cols="12">
          <v-alert type="warning"
                   border="left"
                   v-model="noFileAlert"
                   dismissible
                   elevation="1">
            Brak plików wskazanych do importu
          </v-alert>

          <v-alert type="success"
                   border="left"
                   v-model="showInvoicesMsg"
                   dismissible
                   elevation="1">
            Import raportów zakończony. Zaimportowano faktur: {{invoicesLoaded}}. Błędnych plików: {{invoicesError}}.
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-file-input
              v-model="files"
              color="indigo accent-4"
              accept=".csv"
              counter
              label="Wybierz pliki raportu szczepień z aplikacji Gabinet.gov (rozszerzenie *.csv)"
              multiple
              placeholder="Wybierz pliki raportu szczepień z aplikacji Gabinet.gov (rozszerzenie *.csv)"
              prepend-icon="mdi-paperclip"
              outlined
              counter-string="# plików"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
              >
                {{ text }}
              </v-chip>

              <span
                  v-else-if="index === 2"
                  class="text-overline grey--text text--darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </v-file-input>

        </v-col>

        <v-col cols="12" v-if="showLoading">
          Odczytywanie plików
          <v-progress-linear
              indeterminate
              color="cyan"
          ></v-progress-linear>
        </v-col>

        <v-col cols="12" >
          <v-btn dark color="indigo darker-3" v-if="showGoToInvoicesBtn" link to="/invoices">
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
            Przejdź do listy faktur
          </v-btn>
        </v-col>


      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ReportImporter",
  data: () => ({
    files: [],
    noFileAlert: false,
    showLoading: false,
    timerIds: [],
    loadedFiles: [],
    lastLoadedInvoices: 0,
    showInvoicesMsg: false,
    invoicesLoaded: 0,
    invoicesError: 0,
    showGoToInvoicesBtn: false
  }),
  methods: {
    loadFiles(){
      for(let i=0; i<this.timerIds.length; i++) {
        window.clearTimeout(this.timerIds[i])
      }
      this.timerIds = [];

      this.showInvoicesMsg = false;

      if(this.files.length<1){
        this.noFileAlert = true;

        this.timerIds.push(window.setTimeout(() => {
          this.noFileAlert = false;
        }, 4000));

        return;
      }

      this.showLoading = true;

      setTimeout(() => {
        for(let i=0; i<this.files.length; i++){
          this.$papa.parse(this.files[i], {
            complete: (i<this.files.length-1)
                ? function(results){ this.loadedFiles.push(results); }.bind(this)
                : function(results){ this.loadedFiles.push(results); this.loadData() }.bind(this)
          });
        }
      }, 0);
    },
    loadData(){
      let inv = [];
      if (localStorage.getItem("inv") !== null && localStorage.getItem("inv") !== "") {
        inv = JSON.parse(localStorage.inv);
      }

      let header = {};
      this.invoicesLoaded = 0;
      for(let i=0; i<this.loadedFiles.length; i++){
        let newInv = {
          id: Date.now(),
          nr: '',
          date: null,
          count: 0,
          price: 0,
          sum: 0,
          name: null,
          point_id: null,
          code: ''
        };

        for(let l=0; l<this.loadedFiles[i].data.length; l++){
          let line = this.loadedFiles[i].data[l];

          if(l===0) {
            header = line;
            continue;
          }

          if(line[header.indexOf('pj_kod_tech_czs')]===undefined)
            continue;

          if(!line[header.indexOf('pj_kod_tech_czs')].includes('19.0319.'))
            continue;

          newInv.count++;

          if(newInv.code=='')
            newInv.code = line[header.indexOf('pj_kod_tech_czs')];

          if(newInv.price===0)
            newInv.price = line[header.indexOf('pj_cena_prod_rozl')].replace(',', '.');

          if(newInv.name==null)
            newInv.name = line[header.indexOf('pj_nazwa_prod_rozl')];

          if(newInv.date==null)
            newInv.date = Date.parse(line[header.indexOf('pj_data_szczepienia')]);
          else{
            let currDate = Date.parse(line[header.indexOf('pj_data_szczepienia')]);
            if(newInv.date < currDate)
              newInv.date = currDate;
          }
        }

        if(newInv.count>0) {
          this.invoicesLoaded++;
          newInv.sum = newInv.count * parseFloat(newInv.price);
          //newInv.dateParsed = new DateTime(newInv.date);
          inv.push(newInv);
        }
      }

      if(this.invoicesLoaded>0)
        this.showGoToInvoicesBtn = true;

      localStorage.inv = JSON.stringify(inv);
      this.invoicesError = this.loadedFiles.length - this.invoicesLoaded;
      this.loadedFiles = [];
      this.showLoading = false;
      this.showInvoicesMsg = true;
      this.timerIds.push(window.setTimeout(() => {
        this.showInvoicesMsg = false;
      }, 4000));
    }
  }
}
</script>

<style scoped>

</style>
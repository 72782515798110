<template>
  <v-app id="inspire">
    <Menu></Menu>
    <v-main>
      <v-container class="py-8 px-6" fluid>
        <v-row color="gray">
          <v-col color="gray" cols="12">
            <v-card>
              <router-view transition-mode="out-in"></router-view>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from './components/Menu.vue';

export default {
  name: 'App',
  components: {Menu}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #eceeef;
  margin-top: 60px;
}

.v-main__wrap{
  background-color: #F0F0F0;

}
</style>

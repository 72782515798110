<template>
  <v-card class="mx-auto" max-width="100%">
    <v-system-bar color="indigo darken-2" dark></v-system-bar>
    <v-toolbar color="indigo" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Edytor faktury</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="save()" dark color="green darken-3">
        <v-icon>mdi-content-save</v-icon>
        Zapisz fakturę
      </v-btn>
    </v-toolbar>

    <v-container fluid>
      <v-form ref="invForm">
        <v-row dense>
          <v-col cols="12">
            <v-alert type="success"
                     border="left"
                     v-model="showAlert"
                     dismissible
                     elevation="1">
              Dane faktury zostały zapisane!
            </v-alert>

            <v-alert type="error"
                     border="left"
                     v-model="showError"
                     dismissible
                     elevation="1">
              Nie można zapisać danych faktury, ponieważ zawiera błędy!
            </v-alert>
          </v-col>

          <v-col cols="12" md="6">
            <v-select :items="currentPoints" v-model="inv.point_id" outlined
                      label="Wybierz punkt z listy"
                      item-value="id" item-text="name" required></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="inv.nr"
                          counter="25" :rules="rules.nr" reqired
                          outlined label="Nr faktury"/>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="inv.count"
                          :rules="rules.count" reqired
                          outlined label="Ilość wykonanych szczepień"/>
          </v-col>

          <v-col cols="12" md="12">
            <h5>Wybierz produkt z tabeli poniżej, lub wpisz dane ręcznie w pola kod, opis i cena.</h5>
          </v-col>

          <v-col cols="12" md="12">
            <v-container fluid>
              <v-data-table :headers="headers"
                            :items="productsTpls" ref="productsTable"
                            :items-per-page="30"
                            :hide-default-footer="true"
                            loading-text="Ładowanie danych... Proszę czekać..."
                            no-data-text="Brak produktów do wyświetlenia!"
                            no-results-text="Brak danych do pokazania"
                            locale="pl-PL">
                <template  v-slot:item.options="{ item }">
                  <v-btn color="indigo" small dark tile @click="selectProduct(item.id)"><v-icon>mdi-plus</v-icon> Wybierz</v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="inv.code" counter="14"
                          :rules="rules.code" reqired
                          outlined label="Kod tech. CZS"/>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="inv.code2" counter="14"
                          :rules="rules.code2"
                          outlined label="Kod rozl."/>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="inv.name"
                          counter="250" :rules="rules.name" reqired
                          outlined label="Opis usługi"/>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="inv.price"
                          :rules="rules.price" reqired
                          outlined label="Cena jednostkowa"/>
          </v-col>




          <v-col cols="12" md="8">

            <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="inv.date"
                    label="Data wykonania usługi"
                    hint="Wybierz datę szczepienia, lub wskaż datę ostatniego jeśli rozliczasz ich wiele"
                    persistent-hint
                    outlined
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="inv.date"
                  no-title
                  @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" md="4">
            <v-alert :color="alertColor" style="text-align: right;">
              Wartość faktury: <b>{{ invSum }}</b>
            </v-alert>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn @click="save()" dark color="green darken-3">
              <v-icon>mdi-content-save</v-icon>
              Zapisz fakturę
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Invoice",
  data() {
    var self = this;
    return {
      showAlert: false,
      showError: false,
      timerIds: [],
      currentPoints: [],
      productsTpls: [
        {id: 1, name: 'Szczepienie przeciwko SARS-CoV-2 w punkcie szczepień bez transportu pacjenta', price: '61.24', code: '19.0319.901.02', code2: '99.03.0801', distinct: 1},
        {id: 2, name: 'Szczepienie ochronne przeciwko grypie ', price: '21.83', code: '19.0319.806.02', code2: '99.03.0806', distinct: 2},
        /*
        {id: 3, name: 'Szczepienie ochronne przeciwko grypie z wykorzystaniem szczepionki zakupionej samodzielnie przez podmiot', price: '60.30', code: '19.0319.807.02'},
        {id: 4, name: 'Wykonanie testu antygenowego na obecność wirusa SARS-CoV-2', price: '35.83', code: '19.0319.505.02'},
        {id: 5, name: 'Wykonanie testu antygenowego na obecność wirusa SARS-CoV-2 (bez kosztu testu) ', price: '23.24', code: '19.0319.506.02'}
        */
      ],
      headers: [
        {text: "Id", value: "id", sortable: true},
        {text: "Kod tech. CZS", value: "code", sortable: true},
        {text: "Kod rozlicz.", value: "code2", sortable: true},
        {text: "Produkt", value: "name", sortable: true},
        {text: "Cena", value: "price", sortable: true},
        {text: "Opcje", value: "options", sortable: false},
      ],
      inv: {
        id: null,
        nr: '',
        date: self.$moment(Date.now()).format("YYYY-MM-DD"),
        count: '1',
        price: '61.24',
        sum: '61.24',
        name: 'Szczepienie przeciwko SARS-CoV-2 w punkcie szczepień bez transportu pacjenta',
        point_id: null,
        code: '19.0319.901.02',
        code2: '99.03.0801',
        distinct: 1
      },
      rules: {
        nr: [
          v => !!v || 'Numer faktury jest wymagany',
          v => v.length <= 25 || 'Numer faktury nie może przekroczyć 25 znaków',
        ],
        code: [
          v => !!v || 'Kod tech. CZS jest wymagany',
          v => v.length <= 14 || 'Kod świadczenia nie może przekroczyć 14 znaków',
        ],
        name: [
          v => !!v || 'Opis usługi jest wymagana',
          v => v.length <= 250 || 'Opis usługi nie może przekraczać 250 znaków'
        ],
        price: [
          v => self.checkPrice(v) || 'Cena jest wymagana',
        ],
        count: [
          v => self.checkCount(v) || 'Ilość jest wymagana',
          v => parseInt(v) > 0 || 'Ilość musi być wartością dodatnią'
        ],
      }
    }
  },
  mounted() {
    let s = localStorage.getItem('settings');
    if(s!=null && s!='') {
      this.currentPoints = JSON.parse(s);
    }

    this.$nextTick(function () {
      // eslint-disable-next-line no-undef
      if (this.$route.params.id != undefined) {
        // eslint-disable-next-line no-undef
        let invId = this.$route.params.id;
        let invList = JSON.parse(localStorage.getItem('inv'));
        for (let i = 0; i < invList.length; i++) {
          if (invList[i].id == invId) {
            this.inv = invList[i];
            this.inv.date = this.$moment(this.inv.date).format("YYYY-MM-DD");
            break;
          }
        }
      }

      if((this.inv.point_id==undefined || this.inv.point_id==null) && this.currentPoints.length>0)
        this.inv.point_id = this.currentPoints[0].id;
    });
  },
  methods: {
    save() {
      for (let i = 0; i < this.timerIds.length; i++) {
        window.clearTimeout(this.timerIds[i])
      }

      let formValidate = this.$refs.invForm.validate();

      if (!formValidate || !this.checkCount() || !this.checkPrice()) {
        this.showAlert = false;
        this.showError = true;
        this.timerIds.push(window.setTimeout(() => {
          this.showError = false;
        }, 4000));
        return;
      }

      this.inv.sum = this.getSum(this.inv.count, this.inv.price);

      if (localStorage.inv == undefined || localStorage.inv == null || localStorage.inv == '')
        localStorage.setItem('inv', '[]');

      let invList = JSON.parse(localStorage.inv);
      if (this.inv.id == null) {
        this.inv.id = Date.now();
        invList.push(this.inv);
      } else {
        for (let i = 0; i < invList.length; i++) {
          if (invList[i].id == this.inv.id) {
            invList[i] = this.inv;
          }
        }
      }

      localStorage.setItem('inv', JSON.stringify(invList));

      this.showError = false;
      this.showAlert = true;
      this.timerIds.push(window.setTimeout(() => {
        this.showAlert = false;
      }, 4000));
    },
    selectProduct(id){
      for(let i =0; i<this.productsTpls.length; i++){
        if(this.productsTpls[i].id == id){
          this.inv.code = this.productsTpls[i].code;
          this.inv.code2 = this.productsTpls[i].code2;
          this.inv.name = this.productsTpls[i].name;
          this.inv.price = this.productsTpls[i].price;
          this.inv.distinct = this.productsTpls[i].distinct;
          break;
        }
      }
    },
    checkPrice(val) {
      if (val === undefined)
        val = this.inv.price;
      if (val == null || val === '')
        return false;

      let ok = !isNaN(parseFloat(val));

      return ok && parseFloat(val) > 0;
    },
    checkCount(val) {
      if (val == undefined)
        val = this.inv.count;

      if (val == null || val === '')
        return false;

      let ok = !isNaN(parseInt(val));

      return ok && parseInt(val)> 0;
    },
    getSum(count, price) {
      return (parseInt(count) * parseFloat(price.replace(',', '.'))).toFixed(2);
    },
  },
  computed: {
    invSum() {
      let r = (parseInt(this.inv.count) * parseFloat(this.inv.price.replace(',', '.'))).toFixed(2);
      if (isNaN(r))
        r = 'Błędne dane';
      return r;
    },
    alertColor() {
      if (isNaN(this.invSum) || this.invSum == 0)
        return 'warning'
      else
        return 'success';
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card class="mx-auto" max-width="100%">
    <v-system-bar color="indigo darken-2" dark></v-system-bar>
    <v-toolbar color="indigo" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Lista faktur dla NFZ</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn link to="/invoice">
        <v-icon>mdi-plus-circle</v-icon>
        Dodaj fakturę
      </v-btn>
    </v-toolbar>

    <v-container fluid>
      <v-data-table :headers="headers" :footer-props="footerProps"
                    :items="invoices" ref="filesTable"
                    :items-per-page="30"
                    loading-text="Ładowanie danych... Proszę czekać..."
                    no-data-text="Brak faktur do wyświetlenia!"
                    no-results-text="Brak danych do pokazania"
                    locale="pl-PL">

        <template v-slot:item.nr="{ item }">
          <v-chip v-if="item.nr===null || item.nr==''" color="error">Brak numeru</v-chip>
          <v-chip v-else color="success">{{item.nr}}</v-chip>
        </template>

        <template v-slot:item.date="{ item }">
          {{ item.date | moment("YYYY-MM-DD") }}
        </template>

        <template  v-slot:item.options="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="indigo" small dark v-on="on" tile><v-icon>mdi-menu</v-icon></v-btn>
            </template>
            <v-list>
              <v-list-item :to="'/invoice/'+item.id"><v-list-item-title><v-icon left>mdi-text-box</v-icon>Edytuj fakturę</v-list-item-title></v-list-item>
              <v-list-item @click="downloadXml(item)"><v-list-item-title><v-icon left>mdi-printer</v-icon>Pobierz XML</v-list-item-title></v-list-item>
              <v-list-item @click="downloadXml(item, true)"><v-list-item-title><v-icon left>mdi-printer</v-icon>Pobierz EFX</v-list-item-title></v-list-item>
              <v-list-item @click="downloadPdf(item)"><v-list-item-title><v-icon left>mdi-printer</v-icon>Pobierz PDF</v-list-item-title></v-list-item>
              <v-list-item @click="downloadAtt4Xlsx(item)"><v-list-item-title><v-icon left>mdi-printer</v-icon>Pobierz Załącznik 4</v-list-item-title></v-list-item>
              <v-list-item color="warning" @click="askDelete(item)"><v-list-item-title><v-icon left>mdi-delete</v-icon>Usuń fakturę</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="deleteDialog">
        <v-card>
          <v-toolbar color="primary" dark>Usuwanie faktury</v-toolbar>
          <v-card-text>
            <div class="text-h2 pa-12">Potwierdź chęć usunięcia faktury</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn @click="deleteDialog = false">Anuluj</v-btn>
            <v-btn color="warning" @click="deleteItem()">Usuń</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="configDialog">
      <v-card>
        <v-toolbar color="error" dark>Brak danych</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Uzupełnij najpierw dane punktu szczepień!</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="configDialog = false">Zamknij</v-btn>
          <v-btn color="indigo" to="/settings" dark>Uzupełnij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <InvoicePdf2 ref="invoicePdf" v-if="hasPdfInvoice" :data="pdfInvoice"></InvoicePdf2>
  </v-card>
</template>

<script>
import InvoicePdf2 from './InvoicePdf2';
import XLSX from "xlsx";

export default {
  name: "Invoices",
  data: () => ({
    invoices: [],
    pdfInvoice: null,
    deleteDialog: false,
    configDialog: false,
    itemToDelete: null,
    headers: [
      {text: "Nr", value: "nr", sortable: true},
      {text: "Data", value: "date", sortable: true},
      {text: "Produkt", value: "name", sortable: true},
      {text: "Cena", value: "price", sortable: true},
      {text: "Ilość", value: "count", sortable: true},
      {text: "Wartość", value: "sum", sortable: true},
      {text: "Opcje", value: "options", sortable: false},
    ],
    footerProps: {
      'items-per-page-options': [15, 30, 50],
      'items-per-page-text': 'Wyników na stronę'
    },
    funds: [
      {id: '01', name: 'Dolnośląski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Joannitów 6', city: 'Wrocław', postcode: '50-525'},
      {id: '02', name: 'Kujawsko-Pomorski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Łomżyńska 33', city: 'Bydgoszcz', postcode: '85-863'},
      {id: '03', name: 'Lubelski Oddział Wojewódzki NFZ', nip: '9462379932', address: 'ul. Szkolna 16', city: 'Lublin', postcode: '20-124'},
      {id: '04', name: 'Lubuski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Podgórna 9b', city: 'Zielona Góra', postcode: '65-057'},
      {id: '05', name: 'Łódzki Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Kopcińskiego 58', city: 'Łódź', postcode: '90-032'},
      {id: '06', name: 'Małopolski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Ciemna 6', city: 'Kraków', postcode: '31- 053'},
      {id: '07', name: 'Mazowiecki Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Chałubińskiego 8', city: 'Warszawa', postcode: '00-613'},
      {id: '08', name: 'Opolski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Ozimska 72a', city: 'Opole', postcode: '45-310'},
      {id: '09', name: 'Podkarpacki Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Zamkowa 8', city: 'Rzeszów', postcode: '35-032'},
      {id: '10', name: 'Podlaski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Pałacowa 3', city: 'Białystok', postcode: '15-042'},
      {id: '11', name: 'Pomorski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Marynarki Polskiej 148', city: 'Gdańsk', postcode: '80-865'},
      {id: '12', name: 'Śląski Oddział Wojewódzki Narodowego Funduszu Zdrowia z siedzibą w Katowicach', nip: '2050000285', address: 'ul. Kossutha 13', city: 'Katowice', postcode: '40-844'},
      {id: '13', name: 'Świętokrzyski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Jana Pawła II 9', city: 'Kielce', postcode: '25-025'},
      {id: '14', name: 'Warmińsko-Mazurski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Żołnierska 16', city: 'Olsztyn', postcode: '10-561'},
      {id: '15', name: 'Wielkopolski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Grunwaldzka 158', city: 'Poznań', postcode: '60-309'},
      {id: '16', name: 'Zachodniopomorski Oddział Wojewódzki NFZ', nip: '1070001057', address: 'ul. Arkońska 45', city: 'Szczecin', postcode: '71-470'},
    ],

  }),
  mounted(){
    this.invoices = JSON.parse(localStorage.getItem("inv"));
  },
  components:{
    InvoicePdf2
  },
  methods: {
    downloadXml(item, zip=false){
      let tpl = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
          "<komunikat xmlns=\"http://www.nfz.gov.pl/xml/swd-platnik/e-dok-rozlicz/fakt/v2.3\" typ=\"FAKT\" wersja=\"2.3\" id-odb=\"##ODB##\" id-swd=\"##SWD##\" czas-gen=\"##GEN##\" id-inst-swd=\"0\" nr-gen=\"##IDGEN##\" info-aplik-nad=\"RHDEV OIANfzXMLConv\" info-kontakt-nad=\"rafalh@gmail.com\">\n" +
          "<naglowek-dok numer-dok=\"##NR##\" interpretacja-dok=\"DE\" typ-dok=\"P\" duplikat=\"N\" typ-dokum=\"11\" data-wyst-dok-kor=\"##DATA##\" umowa-oddzial=\"##ODB##\" nr-umowy=\"##NUMBER##\" rok-rozlicz=\"##ROK##\" miesiac-rozlicz=\"##MSC##\" data-wystawienia=\"##DATA##\" miejsce-wystawienia=\"##CITY##\" data-dost-wyk=\"##DATA##\" kwota=\"##SUM##\">\n" +
          "\t<sprzedawca nazwa=\"##SNAME##\" nip=\"##NIP##\" regon=\"##REGON##\">\n" +
          "\t\t<adres-telefon kod-pocztowy=\"##POSTCODE##\" miejscowosc=\"##CITY##\" adres=\"##ADDRESS##\" telefon=\"##PHONE##\"/>\n" +
          "\t</sprzedawca>\n" +
          "\t<nabywca nazwa=\"Narodowy Fundusz Zdrowia z siedzibą w Warszawie\" nip=\"1070001057\">\n" +
          "\t\t<adres-telefon kod-pocztowy=\"02-528\" miejscowosc=\"Warszawa\" adres=\"ul. Rakowiecka 26/30\" telefon=\"22 22 572 60 00\" fax=\"22 572 63 33\"/>\n" +
          "\t</nabywca>\n" +
          "\t<odbiorca nazwa=\"##FNAME##\" nip=\"##FNIP##\">\n" +
          "\t\t<adres-telefon kod-pocztowy=\"##FPOSTCODE##\" miejscowosc=\"##FCITY##\" adres=\"##FADDRESS##\"/>\n" +
          "\t</odbiorca>\n" +
          "\t<termin-platnosci>\n" +
          "\t\t<termin-wg-umowy/>\n" +
          "\t</termin-platnosci>\n" +
          "\t<forma-platnosci>\n" +
          "\t\t<przelew>\n" +
          "\t\t\t<konto-wg-umowy/>\n" +
          "\t\t</przelew>\n" +
          "\t</forma-platnosci>\n" +
          "\t<podst-zwol-vat podst-zwol-vat=\"Usługi zwolnione na podstawie art. 43 ust. 1 pkt 18 ustawy o VAT - PKWiU 86\"/>\n" +
          "</naglowek-dok>\n" +
          "<pozycje>\n" +
          "\t<dane-pozycji nr-pozycji=\"1\" zakres-swiadcz=\"##PCODE##\" wyroznik=\"##DISTINCT##\" nazwa-towaru=\"1 - ##NAME##\" jednostka-miary=\"PUNKT\">\n" +
          "\t\t<rozlicz-ilosc-poz-umowy>\n" +
          "\t\t\t<rozlicz-ilosc-wart-akt cena-stawka-brutto=\"##PRICE##\" lb-jedn-rozlicz=\"##COUNT##\" oplata-plat=\"##SUM##\" doplata-pacj=\"0\">\n" +
          "\t\t\t\t<rozlicz-ilosc-wart-akt-vat stawka-vat=\"zw\" cena-stawka-netto=\"##PRICE##\" wart-netto=\"##SUM##\" wart-vat=\"0\"/>\n" +
          "\t\t\t</rozlicz-ilosc-wart-akt>\n" +
          "\t\t</rozlicz-ilosc-poz-umowy>\n" +
          "\t</dane-pozycji>\n" +
          "</pozycje>\n" +
          "<podsum-vat>\n" +
          "\t<podsum-stawki-vat stawka-vat=\"zw\">\n" +
          "\t\t<podsum-stawki-vat-wart-akt wart-netto=\"##SUM##\" wart-vat=\"0.00\" wart-brutto=\"##SUM##\"/>\n" +
          "\t</podsum-stawki-vat>\n" +
          "</podsum-vat>\n" +
          "<podsum-dok do-zaplaty-kwota=\"##SUM##\" do-zaplaty-slownie=\"Do zapłaty: ##SLOWNIE##\">\n" +
          "\t<podsum-dok-faktury>\n" +
          "\t\t<podsum-dok-wart-akt wart-netto=\"##SUM##\" wart-vat=\"0.00\" wart-brutto=\"##SUM##\"/>\n" +
          "\t</podsum-dok-faktury>\n" +
          "</podsum-dok>\n" +
          "</komunikat>";

      let config = null;
      let configs = localStorage.getItem('settings');
      if(configs!=null && configs!='') {
        configs = JSON.parse(configs);

        if(item.point_id==null) {
          item.point_id = configs[0].id;
        }

        for(let i=0; i<configs.length; i++){
          if(configs[i].id == item.point_id){
            config = configs[i];
            break;
          }
        }

        if(config==null || config.number=='') {
          this.configDialog = true;
          return;
        }
      }
      else{
        this.configDialog = true;
        return;
      }
      let fund = null;
      for(let i =0; i<this.funds.length; i++){
        if(this.funds[i].id == config.fund){
          fund = this.funds[i];
          break;
        }
      }

      let distinct = 1;
      if(item.distinct!=undefined){
        if(item.distinct!=null)
          distinct = item.distinct;
      }

      let content = tpl.replaceAll('##NR##', item.nr);
      content = content.replaceAll('##IDGEN##', this.$moment(item.date).format("YYYYMMDD"));
      content = content.replaceAll('##ROK##', this.$moment(item.date).format("YYYY"));
      content = content.replaceAll('##MSC##', this.$moment(item.date).format("MM"));
      content = content.replaceAll('##DATA##', this.$moment(item.date).format("YYYY-MM-DD"));
      content = content.replaceAll('##SUM##', item.sum.replace(',', '.'));
      content = content.replaceAll('##COUNT##', item.count);
      content = content.replaceAll('##PRICE##', item.price.replace(',', '.'));
      content = content.replaceAll('##NAME##', item.name);
      content = content.replaceAll('##ODB##', config.fund);
      content = content.replaceAll("##SWD##", config.pointId);
      content = content.replaceAll("##GEN##", this.$moment(Date.now()).format("YYYY-MM-DD") + 'T' + this.$moment(Date.now()).format("hh:mm:ss"));
      content = content.replaceAll('##SNAME##', config.name);
      content = content.replaceAll('##NUMBER##', config.number);
      content = content.replaceAll("##NIP##", config.nip);
      content = content.replaceAll('##REGON##', config.regon);
      content = content.replaceAll('##CITY##', config.city);
      content = content.replaceAll('##ADDRESS##', config.address);
      content = content.replaceAll("##POSTCODE##", config.postcode);
      content = content.replaceAll('##PHONE##', config.phone);
      content = content.replaceAll("##PCODE##", item.code);
      content = content.replaceAll("##DISTINCT##", distinct);

      let sumParts = item.sum.split('.');
      let slownie = new window.slownie.Slownie().get(sumParts[0]) + ' zł ';
      if(sumParts.length>1)
        slownie += sumParts[1] + '/100';
      else
        slownie += '00/100';

      content = content.replaceAll('##SLOWNIE##', slownie);

      if(fund!=null){
        content = content.replace('##FNAME##', fund.name);
        content = content.replace('##FNIP##', fund.nip);
        content = content.replace('##FCITY##', fund.city);
        content = content.replace('##FADDRESS##', fund.address);
        content = content.replace('##FPOSTCODE##', fund.postcode);
      }



      let blob = new Blob([ content ], { "type" : "text/plain" });
      let link = document.createElement('a');
      if(zip){
        let zipFile = new window.jszip;
        zipFile.file("fv.xml", content);
        zipFile.generateAsync({type:"blob"})
            .then(function(zipBlob) {
              link.href = window.URL.createObjectURL(zipBlob);
              link.download = 'fv.efx';
              link.click();
            });
      }else{
        link.href = window.URL.createObjectURL(blob);
        link.download = 'fv.xml';
        link.click();
      }



    },
    downloadPdf (item) {

      let config = null;
      let configs = localStorage.getItem('settings');
      if(configs!=null && configs!='') {
        configs = JSON.parse(configs);

        if(item.point_id==null) {
          item.point_id = configs[0].id;
        }

        for(let i=0; i<configs.length; i++){
          if(configs[i].id == item.point_id){
            config = configs[i];
            break;
          }
        }

        if(config==null || config.number=='') {
          this.configDialog = true;
          return;
        }
      }
      else{
        this.configDialog = true;
        return;
      }

      let fund = null;
      for(let i =0; i<this.funds.length; i++){
        if(this.funds[i].id == config.fund){
          fund = this.funds[i];
          break;
        }
      }

      item.config = config;
      item.fund = fund;

      this.pdfInvoice = item;
      this.$nextTick(function(){
        this.$refs.invoicePdf.generatePdf();
      });
    },
    rewCode(item){

      if(item.code2!=undefined){
        if(item.code2!=null && item.code2!='')
          return item.code2;
      }

      let code = item.code;
      if(code=='19.0319.801.02')
        return '99.03.0801';

      if(code=='19.0319.806.02')
        return '99.03.0806';

      if(code=='19.0319.807.02')
        return '99.03.0807';

      if(code=='19.0319.505.02')
        return '99.05.0005';

      if(code=='19.0319.506.02')
        return '99.05.0006';

      return code;

    },
    downloadAtt4Xlsx(item){
      let config = null;
      let configs = localStorage.getItem('settings');
      if(configs!=null && configs!='') {
        configs = JSON.parse(configs);

        if(item.point_id==null) {
          item.point_id = configs[0].id;
        }

        for(let i=0; i<configs.length; i++){
          if(configs[i].id == item.point_id){
            config = configs[i];
            break;
          }
        }

        if(config==null || config.number=='') {
          this.configDialog = true;
          return;
        }
      }
      else{
        this.configDialog = true;
        return;
      }

      let fund = null;
      for(let i =0; i<this.funds.length; i++){
        if(this.funds[i].id == config.fund){
          fund = this.funds[i];
          break;
        }
      }

      let jsonData = [
        ['', '', '', '', '', '', ''],
        ['Nazwa podmiotu: ' + config.name, '', '', '', '', ''],
        ['REGON podmiotu: ' + config.regon, '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['OW NFZ: ' + fund.id, '', '', '', '', ''],
        ['', '', '', '', '', '', ''],
        ['Sprawozdanie za miesiąc ' + this.$moment(item.date).format("MM") + '/' + this.$moment(item.date).format("YYYY")+ ' dla: ' + item.name, '', '' ,'','','',''],
        ['Lp', 'Miesiąc wykonania', 'Kod produktu', 'Nazwa produktu rozliczeniowego', 'Liczba wykonanych szczepień', 'Wartość produktu rozliczeniowego (zł)', 'Wartość w zł'],
        ['1', '2', '3', '4', '5', '7', '7=5*6'],
        ['1.', this.$moment(item.date).format("MM"), this.rewCode(item), item.name, item.count, item.price, item.sum],
        ['2.', '', '', '', '', '', ''],
        ['3.', '', '', '', '', '', ''],
        ['4.', '', '', '', '', '', ''],
        ['5.', '', '', '', '', '', ''],
        ['RAZEM', '', '', '',  item.count, '', item.sum],
        ['', '', '', '', '', '', ''],
        ['Miejscowość: ' + config.city, '', '', '', '', ''],
        ['Data: ' + this.$moment().format('YYYY-MM-DD'), '', '', '', '', ''],
        ['Imie i nazwisko sporządzającego: ', '', '', '', '', '', ''],
        ['Nr telefonu: '+ config.phone, '', '', '', '', ''],
        ['Adres email: ', '', '', '', '', '', ''],
      ];

      let worksheet = XLSX.utils.aoa_to_sheet(jsonData);
      worksheet["!merges"] = [
        {s: {r: 1, c: 0}, e: {r: 1, c: 7}},
        {s: {r: 2, c: 0}, e: {r: 2, c: 7}},
        {s: {r: 4, c: 0}, e: {r: 4, c: 7}},
        {s: {r: 6, c: 0}, e: {r: 6, c: 7}},
        {s: {r: 16, c: 0}, e: {r: 16, c: 7}},
        {s: {r: 17, c: 0}, e: {r: 17, c: 7}},
        {s: {r: 18, c: 0}, e: {r: 18, c: 7}},
        {s: {r: 19, c: 0}, e: {r: 19, c: 7}},
        {s: {r: 20, c: 0}, e: {r: 20, c: 7}},
      ];
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, "SheetJS");
      XLSX.writeFile(new_workbook, "Zalacznik4.xlsx");
    },
    askDelete(item){
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    deleteItem(){
      for(let i=0; i<this.invoices.length; i++){
        if(this.invoices[i].id == this.itemToDelete.id){
          this.itemToDelete=null;
          this.invoices.splice(i, 1);
          break;
        }
      }

      localStorage.inv = JSON.stringify(this.invoices);
      this.deleteDialog = false;
    },
    invSum(count, price){
      return (parseInt(count) * parseFloat(price)).toFixed(2);
    },
  },
  computed:{
    hasPdfInvoice(){
      return this.pdfInvoice!=null;
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-navigation-drawer
      absolute
      color="indigo"
      dark
      app
      permanent
  >
    <v-sheet v-if="hasData"
        color="indigo darken-2"
        class="pa-3"
    >
      <v-row>
        <v-col cols="3">
          <v-avatar
              class="mb-4"
              color="green darken-1"
              size="48"

          ><span class="white--text text-h5"><v-icon>mdi-account</v-icon></span></v-avatar>
        </v-col>
        <v-col cols="9" style="padding-top:20px;">
          <div class="white--text text-h6">Wczytano dane</div>
        </v-col>
      </v-row>
      <div style="font-size:smaller;">Twoje dane są przechowywane w magazynie przeglądarki</div>
    </v-sheet>

    <v-sheet v-else
        color="indigo darken-2"
        class="pa-3"
    >
      <v-row>
      <v-col cols="3">
      <v-avatar
          class="mb-4"
          color="orange darken-1"
          size="48"

      ><span class="white--text text-h5">?</span></v-avatar>
      </v-col>
      <v-col cols="9" style="padding-top:20px;">
        <div class="white--text text-h6">Nowy użytkownik</div>
      </v-col>
      </v-row>
      <div style="font-size:smaller;">Wprowadź dane punktu szczepień, aby uwzględnić je w fakturach</div>
    </v-sheet>

    <v-divider></v-divider>

    <v-list>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Informacje</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/settings">
        <v-list-item-icon>
          <v-icon>mdi-domain</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dane punktu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/import">
        <v-list-item-icon>
          <v-icon>mdi-folder-open</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Importuj raport</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/invoice">
        <v-list-item-icon>
          <v-icon>mdi-plus-box</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Wprowadź fakturę</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/invoices">
        <v-list-item-icon>
          <v-icon>mdi-view-list</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Lista faktur</v-list-item-title>
        </v-list-item-content>
      </v-list-item>



    </v-list>
    <template v-slot:append>
      <div class="pa-2">
        <v-btn block color="red" @click="askDeleteData()">
          Usuń dane
        </v-btn>
      </div>
    </template>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="deleteDialog">
      <v-card>
        <v-toolbar color="primary" dark>Usuwanie danych</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Potwierdź chęć usunięcia danych z przeglądarki!</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="deleteDialog = false">Anuluj</v-btn>
          <v-btn color="warning" @click="deleteData()">Usuń</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Menu",
  data(){
    return {
      hasData: false,
      deleteDialog: false
    }
  },
  mounted() {
    this.$root.$on('recheck-data', () => {
      this.checkData();
    });

    this.checkData();
  },
  methods: {
    checkData(){
      this.hasData = false;
      let s = localStorage.getItem('settings');
      if(s!=null && s!='') {
        s = JSON.parse(s);
        if(s.length > 0)
          if(s[0].name!=null && s[0].name!='')
            this.hasData = true;
      }
    },
    askDeleteData(){
      this.deleteDialog = true;
    },
    deleteData(){
      this.deleteDialog = false;
      localStorage.setItem('settings', '');
      localStorage.setItem('inv', '');
      this.checkData();
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-card class="mx-auto" max-width="100%">
    <v-system-bar color="indigo darken-2" dark></v-system-bar>
    <v-toolbar color="indigo" dark>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
      <v-toolbar-title>Ustawienia punktu szczepień</v-toolbar-title>
      <v-spacer></v-spacer>


    </v-toolbar>

    <v-container fluid>
      <v-form ref="pointForm">
        <v-row>
          <v-col cols="12" md="8">
            <v-select :items="currentPoints" v-model="currentId" outlined
                      @change="loadPointData()" label="Wybierz punkt z listy"
                      item-value="id" item-text="name" dense></v-select>
          </v-col>

          <v-col cols="12" md="4">
            <v-btn @click="addNewPoint()" color="primary" class="mx-2">
              <v-icon>mdi-plus-circle</v-icon> Dodaj nowy punkt
            </v-btn>

            <v-btn @click="deletePoint()" color="warning" class="mx-2">
              <v-icon>mdi-minus-circle</v-icon> Usuń punkt
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-alert type="success"
                     border="left"
                     v-model="showAlert"
                     dismissible
                     elevation="1">
              Dane punktu szczepień zostały zaktualizowane!
            </v-alert>

            <v-alert type="error"
                     border="left"
                     v-model="showError"
                     dismissible
                     elevation="1">
              Nie można zapisać danych ponieważ formularz zawiera błędy!
            </v-alert>
          </v-col>


          <v-col cols="12" md="3">
            <v-select
                :items="funds"
                v-model="settings.fund"
                :rules="rules.fund"
                label="Wybierz odbiorce faktur (oddział NFZ)"
                item-value="id" item-text="name"
                outlined required
            ></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="settings.pointId"
                          counter="16" :rules="rules.pointId" reqired
                          outlined label="Kod świadczeniodawcy"/>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="settings.number"
                          counter="48" :rules="rules.number" reqired
                          outlined label="Numer umowy"/>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field v-model="settings.name"
                          counter="250" :rules="rules.name" reqired
                          outlined label="Nazwa świadczeniodawcy"/>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="settings.nip"
                          counter="10" :rules="rules.nip" reqired
                          outlined label="NIP"/>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="settings.regon"
                          counter="9" :rules="rules.regon" reqired
                          outlined label="REGON"/>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="settings.postcode"
                          counter="6" :rules="rules.postcode" reqired
                          outlined label="Kod pocztowy"/>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="settings.city"
                          counter="60" :rules="rules.city" reqired
                          outlined label="Miejscowość"/>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="settings.address"
                          counter="82" :rules="rules.address" reqired
                          outlined label="Adres"/>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="settings.phone"
                          counter="9" :rules="rules.phone" reqired
                          outlined label="Telefon"/>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn @click="save()" dark color="green darken-3">
              <v-icon>mdi-content-save</v-icon> Zapisz dane
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="deleteDialog">
      <v-card>
        <v-toolbar color="primary" dark>Usuwanie punktu</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Potwierdź chęć usunięcia punktu</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="deleteDialog = false">Anuluj</v-btn>
          <v-btn color="warning" @click="confirmDeletePoint()">Usuń</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" max-width="600" v-model="warningDialog">
      <v-card>
        <v-toolbar color="error" dark>Usuwanie punktu</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Posiadasz już tylko jeden zdefiniowany punkt!</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="warningDialog = false">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "Settings",
  data(){
    var self = this;
    return {
      currentId: 0,
      currentPoints: [],
      showAlert: false,
      showError: false,
      deleteDialog: false,
      warningDialog: false,
      timerIds: [],
      settings: {
        fund: '',
        pointId: '',
        number: 'Nowy punkt',
        name: '',
        nip: '',
        regon: '',
        city: '',
        address: '',
        postcode: '',
        phone: ''

      },
      funds: [
        {id: '01', name: 'Dolnośląski'},
        {id: '02', name: 'Kujawsko-Pomorski'},
        {id: '03', name: 'Lubelski'},
        {id: '04', name: 'Lubuski'},
        {id: '05', name: 'Łódzki'},
        {id: '06', name: 'Małopolski'},
        {id: '07', name: 'Mazowiecki'},
        {id: '08', name: 'Opolski'},
        {id: '09', name: 'Podkarpacki'},
        {id: '10', name: 'Podlaski'},
        {id: '11', name: 'Pomorski'},
        {id: '12', name: 'Śląski'},
        {id: '13', name: 'Świętokrzyski'},
        {id: '14', name: 'Warmińsko-Mazurski'},
        {id: '15', name: 'Wielkopolski'},
        {id: '16', name: 'Zachodniopomorski'},
      ],
      rules: {
        fund: [
          v => !!v || 'Wybór wymagany'
        ],
        pointId: [
          v => !!v || 'Kod świadczeniodawcy jest wymagany',
          v => v.length <= 16 || 'Kod świadczeniodawcy nie może przekroczyć 16 znaków',
        ],
        number: [
          v => !!v || 'Numer umowy jest wymagany',
          v => v.length <= 48 || 'Kod świadczeniodawcy nie może przekroczyć 48 znaków',
        ],
        name: [
          v => !!v || 'Nazwa jest wymagana',
          v => v.length <= 250 || 'Pole może zawierać maksymalnie 250 znaków'
        ],
        city: [
          v => !!v || 'Miasto jest wymagane',
          v => v.length <= 60 || 'Pole może mieć maksymalnie 60 znaków'
        ],
        address: [
          v => !!v || 'Adres jest wymagany',
          v => v.length <= 82 || 'Pole może mieć maksymalnie 82 znaki'
        ],
        postcode: [
          v => !!v || 'Kod pocztowy jest wymagany',
          v => v.length <= 6 || 'Pole może mieć maksymalnie 6 znaków'
        ],
        phone: [
          v => !!v || 'Nr telefonu jest wymagany',
          v => v.length == 9 || 'Numer telefonu musi mieć 9 cyfr',
          v => /^\d+$/.test(v) || 'Numer telefonu może posiadać tylko cyfry'
        ],
        nip: [
          v => !!v || 'NIP jest wymagany',
          v => self.isValidNip(v) || 'NIP nie prawidłowy'
        ],
        regon: [
          v => !!v || 'REGON jest wymagany',
          v => self.isValidRegon(v) || 'REGON nie prawidłowy'
        ],
      }
    }
  },
  mounted() {

    this.$nextTick(function(){
      let s = localStorage.getItem('settings');
      if(s!=null && s!='') {
        let storedPoints = JSON.parse(s);
        this.settings = storedPoints[0];
        this.currentPoints = storedPoints;
      }
      else {
        this.settings = {
          id: Date.now(),
          fund: null,
          point_id: '',
          name: 'Nowy punkt',
          nip: '',
          regon: '',
          city: '',
          address: '',
          postcode: '',
          phone: ''
        };
        this.currentPoints.push(this.settings);
      }
      this.currentId = this.settings.id;
    });

  },
  methods: {
    save(){
      for(let i=0; i<this.timerIds.length; i++) {
        window.clearTimeout(this.timerIds[i])
      }

      let formValidate = this.$refs.pointForm.validate();

      if(!formValidate){
        this.showAlert = false;
        this.showError = true;
        this.timerIds.push(window.setTimeout(() => {
          this.showError = false;
        }, 4000));
        return;
      }

      let s = localStorage.getItem('settings');
      let storedPoints = [];
      if(s!=null && s!='') {
        storedPoints = JSON.parse(s);
      }

      storedPoints[this.currentIndex] = this.settings;

      localStorage.setItem('settings', JSON.stringify(storedPoints));

      this.currentPoints = storedPoints;

      this.showError = false;
      this.showAlert = true;
      this.$root.$emit('recheck-data');
      this.timerIds.push(window.setTimeout(() => {
        this.showAlert = false;
      }, 4000));
    },
    addNewPoint(){
      let s = localStorage.getItem('settings');
      let storedPoints = [];
      if(s!=null && s!='') {
        storedPoints = JSON.parse(s);
      }

      storedPoints.push({
        id: Date.now(),
        fund: null,
        pointId: '',
        name: 'Nowy punkt',
        nip: '',
        regon: '',
        city: '',
        address: '',
        postcode: '',
        phone: ''
      });

      localStorage.setItem('settings', JSON.stringify(storedPoints));
      this.currentPoints = storedPoints;
      this.currentId = this.currentPoints[this.currentPoints.length-1].id;
      this.settings = this.currentPoints[this.currentPoints.length-1];

    },
    deletePoint(){
      let s = localStorage.getItem('settings');
      if(s!=null && s!='') {
        let storedPoints = JSON.parse(s);
        if(storedPoints.length>1){
          this.deleteDialog = true;
        }else{
          this.warningDialog = true;
        }
      }
    },
    confirmDeletePoint(){
      this.deleteDialog = false;
      let s = localStorage.getItem('settings');
      let storedPoints = JSON.parse(s);
      storedPoints.splice(this.currentIndex, 1);
      this.currentPoints = storedPoints;
      this.currentId = storedPoints[0].id
      localStorage.setItem('settings', JSON.stringify(storedPoints));
    },
    loadPointData(){
      let s = localStorage.getItem('settings');
      if(s!=null && s!='') {
        let storedPoints = JSON.parse(s);
        this.settings = storedPoints[this.currentIndex];
      }
    },
    isValidNip(nip) {
      if(typeof nip !== 'string')
        return false;

      nip = nip.replace(/-/g,"");

      let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
      let sum = 0;
      let controlNumber = parseInt(nip.substring(9, 10));
      let weightCount = weight.length;
      for (let i = 0; i < weightCount; i++) {
        sum += (parseInt(nip.substr(i, 1)) * weight[i]);
      }

      return sum % 11 === controlNumber;
    },
    isValidRegon(regon) {
      let reg = /^[0-9]{9}$/;
      if(!reg.test(regon))
        return false;
      else
      {
        let digits = (""+regon).split("");
        let checksum = (8*parseInt(digits[0]) + 9*parseInt(digits[1]) + 2*parseInt(digits[2]) + 3*parseInt(digits[3]) + 4*parseInt(digits[4]) + 5*parseInt(digits[5]) + 6*parseInt(digits[6]) + 7*parseInt(digits[7]))%11;
        if(checksum == 10)
          checksum = 0;

        return (parseInt(digits[8])==checksum);
      }
    }
  },
  computed: {
    currentIndex(){
      for(let i = 0; i<this.currentPoints.length; i++){
        if(this.currentPoints[i].id == this.currentId)
          return i;
      }

      return 0;
    }
  }
}
</script>

<style scoped>

</style>
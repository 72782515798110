import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import VuePapaParse from 'vue-papa-parse';
import VueXlsx from 'vue-js-xlsx';
import JSZip from 'jszip';

import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'


Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(VuePapaParse);
Vue.use(require('vue-moment'));
Vue.use(VueXlsx);
Vue.use(JSZip);
Vue.use(require("jszip"));

window.slownie = require('slownie');
window.jszip = require("jszip");

const routes = [
  { path: '/', component: require('./components/NfzConv.vue').default },
  { path: '/settings', component: require('./components/Settings.vue').default },
  { path: '/import', component: require('./components/Importer.vue').default },
  { path: '/invoices', component: require('./components/Invoices.vue').default },
  { path: '/invoice', component: require('./components/Invoice.vue').default },
  { path: '/invoice/:id', component: require('./components/Invoice.vue').default },
];

const router = new VueRouter({  routes:routes  });

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  vuetify: new Vuetify(),
  router
}).$mount('#app');

<template>
  <div class="px-10" style="padding-top:50px; padding-bottom:50px;">

    <p class="text-h4 text-center mx-9">
      "Rozliczenia z NFZ" to proste narzędzie do generowania faktur dla NFZ-tu w postaci plików XML
    </p>


    <v-row class="mx-9" style="margin-top:50px;">
      <v-alert
          outlined
          type="success"
          text
      >
        <b>Ważne!</b> Nie przetwarzamy Twoich danych, wszelkie informacje dotyczące Twojego punktu szczepień jak i faktur,
        są przechowywane na Twoim komputerze w magazynie danych przeglądarki. Na żadnym etapie przetwarzania czy generowania plików nie wysyłasz danych nigdzie poza swoje urządzenie!
      </v-alert>
    </v-row>

    <v-row class="mx-9" style="margin-top:40px;">
      <p class="text-h5 text-start">
        Jak zaczać?
      </p>
    </v-row>

    <v-row class="mx-9" style="margin-top:40px;">
    <p class="text-h5 text-start">
      1. Uzupełnij dane swojego punktu szczepień
    </p>
    </v-row>

    <v-row class="mx-9">
      <v-btn to="/settings">Uzupełnij dane punktu szczepień</v-btn>
    </v-row>

    <v-row class="mx-9" style="margin-top:40px;">
    <p class="text-h5 text-start">
      2. Wprowadź fakturę lub zaimportuj raport szczepień z Gabinet.gov
    </p>
    </v-row>

    <v-row class="mx-9">
      <v-btn to="/invoice">Wprowadź fakturę</v-btn>
      <v-divider vertical class="mx-9">lub</v-divider>
      <v-btn to="/import">Importuj raport</v-btn>
    </v-row>

    <v-row class="mx-9" style="margin-top:40px;">
    <p class="text-h5 text-start">
      3. Na liście faktur wybierz opcję "Generuj XML"
    </p>
    </v-row>

    <v-row class="mx-9">
      <v-btn to="/invoices">Lista faktur</v-btn>
    </v-row>



  </div>
</template>

<script>
export default {
  name: "NfzConv"
}
</script>

<style scoped>

</style>